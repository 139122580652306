import styled from 'styled-components';
import { GlobalStyle as GS } from "./index.style"
import FeatureBlock from '../components/FeatureBlock';
import { rgba } from "polished"
import { themeApply, themeRequire } from "../components/theme-utils"
import Text from "../components/Text";

export const GlobalStyle = GS;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .sticky-nav-active {
    .app_navbar {
      background: ${themeRequire('colors.white')};
      box-shadow: 0 3px 8px 0 ${themeApply('colors.info', color => rgba(color, 0.08))};
      padding: 15px 0;
      .logo-main {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            color: ${themeRequire('colors.black')};
            &:hover {
              color: ${themeRequire('colors.primary')};
            }
          }
          &.is-current {
            a {
              color: ${themeRequire('colors.primary')};
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: ${themeRequire('colors.primary')};
      }
    }
  }

  .app_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: ${themeRequire('colors.warning')};
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: ${themeRequire('colors.white')};
          transition: 0.15s ease-in-out;
          &:hover {
            color: ${themeRequire('colors.warning')};
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
`;

export const FeatureItem = styled(FeatureBlock)`
  position: relative;
  padding: 50px 30px;
  border: 1px solid ${themeRequire('colors.offWhite')};
  border-radius: 5px;
  background-color: ${themeRequire('colors.white')};
  transition: 0.35s ease-in-out;
  @media (max-width: 768px) and (min-width: 768px) {
    padding: 30px 20px;
  }
  @media (max-width: 575px) {
    padding: 40px 25px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    background: linear-gradient(
      138deg,
      ${themeRequire('colors.warning')} 0%,
      ${themeRequire('colors.danger')} 100%
    );
    transition: 0.35s ease-in-out;
  }

  & > div {
    position: relative;
  }

  h2,
  p {
    transition: 0.35s ease-in-out;
  }

  .hover-shape {
    width: 20px;
    height: auto;
    position: absolute;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(260deg);
  }

  .hover-shape-1 {
    left: 0;
    top: 20px;
  }

  .hover-shape-2 {
    right: 29%;
    top: 0;
  }

  .hover-shape-3 {
    right: 0;
    bottom: 35%;
  }

  .hover-shape-4 {
    right: 30%;
    bottom: 0;
  }

  .hover-shape-5 {
    left: 0;
    bottom: 30%;
  }

  .icon__wrapper {
    margin-bottom: 40px;
    @media (max-width: 768px) and (min-width: 768px) {
      margin-bottom: 30px;
    }
    @media (max-width: 575px) {
      margin-bottom: 25px;
    }
    i {
      line-height: 1;
      font-size: 65px;
      transition: 0.35s ease-in-out;
      @media (max-width: 768px) and (min-width: 768px) {
        font-size: 50px;
      }
      &.info {
        color: ${themeRequire('colors.info')};
      }
      &.warning {
        color: ${themeRequire('colors.warning')};
      }
      &.success {
        color: ${themeRequire('colors.success')};
      }
    }
  }

  .button__wrapper {
    a {
      color: ${themeRequire('colors.labelColor')};
      font-size: 24px;
      transition: 0.35s ease-in-out;
      @media (max-width: 768px) and (min-width: 768px) {
        font-size: 20px;
      }
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
  }

  &:hover {
    background-color: ${themeRequire('colors.warning')};
    &:before {
      opacity: 0.57;
    }

    .hover-shape-1 {
      left: -40px;
      top: 20px;
    }
    .hover-shape-2 {
      right: 29%;
      top: -47px;
    }
    .hover-shape-3 {
      right: -27px;
      bottom: 35%;
    }
    .hover-shape-4 {
      right: 30%;
      bottom: -60px;
    }
    .hover-shape-5 {
      left: -35px;
      bottom: 30%;
    }
    .hover-shape {
      transform: rotate(0);
      opacity: 1;
    }

    h2,
    p {
      color: ${themeRequire('colors.white')};
    }

    .icon__wrapper {
      i {
        color: ${themeRequire('colors.white')};
      }
    }

    .button__wrapper {
      a {
        color: ${themeRequire('colors.white')};
      }
    }
  }  
`;


export const MarkdownText = styled(Text)`
  a {
    text-decoration: underline;
    color: ${themeRequire('colors.textColor')};
  }
  li {
    line-height: 1.75rem;
    margin-bottom: .5rem;
  }
  ul {
    padding-left: 1.5rem;
    li {
      list-style-type: disc;
    }
  }
  ol {
    padding-left: 1.5rem;
    li {
      list-style-type: decimal;
    }
  }
`;
