import React from "react"
import PropTypes from 'prop-types';
import Box from '../../../components/Box';
import Image from '../../../components/Image';
import Heading from '../../../components/Heading';
import Container from '../../../components/UI/Container';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from './liveLinks.style';
import { MarkdownText } from '../../docs.style';
import { graphql, useStaticQuery } from "gatsby"

const LiveLinksSection = ({ row, col }) => {
  const { docsYaml: { liveLinks: section } } = useStaticQuery(graphql`
    query {
      docsYaml {
        liveLinks {
          anchor
          title
          heading
          image {
            publicURL
          }
          description
        }
      }
    }
  `);

  return (
    <BlockWrapper id={section.anchor}>
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
            <Image src={section.image.publicURL} alt={section.title} />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content={section.title} />
              <Heading content={section.heading} />
              <MarkdownText
                as="div"
                dangerouslySetInnerHTML={{__html: section.description}}
              />
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// LiveLinksSection style props
LiveLinksSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// LiveLinksSection default style
LiveLinksSection.defaultProps = {
  // LiveLinksSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // LiveLinksSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default LiveLinksSection;
