import React, { Fragment } from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "../theme"
import { ResetCSS } from "../assets/css/style"
import { ContentWrapper, GlobalStyle } from "../containers/index.style"

import Footer from "../containers/Common/Footer"
import SEO from "../components/seo"
import Sticky from "react-stickynode"
import { DrawerProvider } from "../contexts/DrawerContext"
import Navbar from "../containers/Docs/Navbar"
import TopicSection from "../containers/Docs/Topic"
import TicketChannelsSection from "../containers/Docs/TicketChannels"
import CommandSection from "../containers/Docs/Command"
import InteractiveMessagesSection from "../containers/Docs/InteractiveMessages"
import LiveLinksSection from "../containers/Docs/LiveLinks"
import PortalsSection from "../containers/Docs/Portals"

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <TopicSection />
          <TicketChannelsSection />
          <CommandSection />
          <InteractiveMessagesSection />
          <LiveLinksSection />
          <PortalsSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
