import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import Heading from '../../../components/Heading';
import Container from '../../../components/UI/Container';
import { FeatureItem } from '../../docs.style';
import ShapeOne from '../../../assets/image/shape-1.svg';
import ShapeTwo from '../../../assets/image/shape-2.svg';
import ShapeThree from '../../../assets/image/shape-3.svg';
import ShapeFour from '../../../assets/image/shape-4.svg';
import ShapeFive from '../../../assets/image/shape-5.svg';
import styled from "styled-components"
import { themeRequire } from "../../../components/theme-utils"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Image from "../../../components/Image"

const TopicHeading = styled(Heading)`
  white-space: nowrap;
`;

const TopicContainer = styled(Box)`
  padding: 150px 0 50px 0;
  background-image: linear-gradient(
    155deg,
    ${themeRequire('colors.primaries.3')} 0%,
    ${themeRequire('colors.primaries.5')} 45%,
    ${themeRequire('colors.primaries.4')} 100%
  );
  background-size: 100% 140px;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  
  p {
    flex-grow: 1;
  }
`;


const TopicSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  featureItemHeading,
  featureItemDes,
}) => {
  const { docsYaml: { topics: section }, allDocsTopicsListYaml: { nodes: topics } } = useStaticQuery(graphql`
    query {
      docsYaml {
        topics {
          anchor
          title
          heading
          description
        }
      }
      allDocsTopicsListYaml {
        nodes {
          href
          title
          icon {
            publicURL
          }
          animation
          description
        }
      }
    }
  `);

  return (
    <TopicContainer {...sectionWrapper} id={section.anchor}>
      <Container>
        <Box {...secTitleWrapper}>
          <Fade bottom cascade>
            <Text {...secText} content={section.title} />
            <Heading
              {...secHeading}
              content={section.heading}
            />
          </Fade>
        </Box>

        <Text
          as="div"
          dangerouslySetInnerHTML={{__html: section.description}}
        />

        <Box {...row}>
          {topics.map((topic, index) => (
            <Box {...col} key={`topic-${index}`}>
              {topic.animation ? (
                <Fade bottom delay={index * 120}>
                  <AnchorLink href={topic.href}>
                    <FeatureItem
                      title={
                        <TopicHeading
                          {...featureItemHeading}
                          content={topic.title}
                        />
                      }
                      description={
                        <Text
                          {...featureItemDes}
                          as="div"
                          dangerouslySetInnerHTML={{ __html: topic.description }}
                        />
                      }
                      icon={
                        <Image
                          src={topic.icon.publicURL}
                          alt={topic.title}
                        />
                      }
                      additionalContent={
                        <>
                          <img
                            className="hover-shape-1 hover-shape"
                            src={ShapeOne}
                            alt="Shape One"
                          />
                          <img
                            className="hover-shape-2 hover-shape"
                            src={ShapeTwo}
                            alt="Shape Two"
                          />
                          <img
                            className="hover-shape-3 hover-shape"
                            src={ShapeThree}
                            alt="Shape Three"
                          />
                          <img
                            className="hover-shape-4 hover-shape"
                            src={ShapeFour}
                            alt="Shape Four"
                          />
                          <img
                            className="hover-shape-5 hover-shape"
                            src={ShapeFive}
                            alt="Shape Five"
                          />
                        </>
                      }
                    />
                  </AnchorLink>
                </Fade>
              ) : (
                <AnchorLink href={topic.href}>
                  <FeatureItem
                    title={
                      <Heading
                        {...featureItemHeading}
                        content={topic.title}
                      />
                    }
                    description={
                      <Text
                        {...featureItemDes}
                        as="div"
                        dangerouslySetInnerHTML={{__html: topic.description}}
                      />
                    }
                    icon={<i className={topic.icon}/>}
                    additionalContent={
                      <>
                        <img
                          className="hover-shape-1 hover-shape"
                          src={ShapeOne}
                          alt="Shape One"
                        />
                        <img
                          className="hover-shape-2 hover-shape"
                          src={ShapeTwo}
                          alt="Shape Two"
                        />
                        <img
                          className="hover-shape-3 hover-shape"
                          src={ShapeThree}
                          alt="Shape Three"
                        />
                        <img
                          className="hover-shape-4 hover-shape"
                          src={ShapeFour}
                          alt="Shape Four"
                        />
                        <img
                          className="hover-shape-5 hover-shape"
                          src={ShapeFive}
                          alt="Shape Five"
                        />
                      </>
                    }
                  />
                </AnchorLink>
              )}
            </Box>
          ))}
        </Box>
      </Container>
    </TopicContainer>
  );
};

TopicSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  featureItemHeading: PropTypes.object,
  featureItemDes: PropTypes.object,
};

TopicSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '80px', '80px'],
    id: 'feature_section',
  },
  secTitleWrapper: {
    mb: ['50px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'danger',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 1 / 2, 1 / 3, 1 / 3],
    pr: '15px',
    pl: '15px',
    mb: '30px',
  },
  featureItemHeading: {
    fontSize: ['18px', '18px', '16px', '20px'],
    fontWeight: '400',
    color: 'headingColor',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '10px'],
    letterSpacing: '-0.020em',
    maxWidth: ['auto', 'auto', 'auto', '180px'],
  },
  featureItemDes: {
    fontSize: ['14px', '14px', '14px', '15px'],
    lineHeight: '1.75',
    color: 'textColor',
    mb: ['20px', '20px', '20px', '20px'],
  },
};

export default TopicSection;
