import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Box from "../../../components/Box"
import Heading from "../../../components/Heading"
import Image from "../../../components/Image"
import Container from "../../../components/UI/Container"

import { CommandAbout, CommandDetails, CommandIcon, CommandItem, CommandTOC, CommandTOCItem } from "./command.style"
import BlockWrapper, { ContentWrapper } from "../TicketChannels/ticketChannels.style"
import Text from "../../../components/Text"
import { MarkdownText } from "../../docs.style"

const CommandSection = ({
  secTitle,
  col,
  cmdTitle,
  cmdDescription,
}) => {
  const { docsYaml: { commands: section }, allDocsCommandsListYaml: { nodes: cmds } } = useStaticQuery(graphql`
    query {
      docsYaml {
        commands {
          anchor
          title
          heading
          description
        }
      }
      allDocsCommandsListYaml {
        nodes {
          command
          title         
          icon {
            publicURL
          }
          description
        }
      }
    }
  `);

  return (
    <BlockWrapper id={section.anchor}>
      <Container>
        <Box className="col" {...col}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content={section.title} />
              <Heading {...secTitle} content={section.heading} />
              <MarkdownText
                as="div"
                dangerouslySetInnerHTML={{__html: section.description}}
              />
            </ContentWrapper>
            <CommandTOC>
              {cmds.map((cmd, index) => (
                <CommandTOCItem key={`cmd-idx-${index}`}>
                  <a href={`#cmd-${cmd.command}`}>
                    <Text content={cmd.title} />
                    <Image src={cmd.icon.publicURL} alt={`${cmd.command} Icon`} />
                    <Text content={`/${cmd.command}`} />
                  </a>
                </CommandTOCItem>
              ))}
            </CommandTOC>
          </Box>
          <Box>
            {cmds.map((cmd, index) => (
              <Box {...col} key={`cmd-item-${index}`} id={`cmd-${cmd.command}`}>
                <CommandItem>
                  <CommandDetails>
                    <CommandAbout>
                      <CommandIcon>
                        <Image
                          src={cmd.icon.publicURL}
                          alt={`cmd-icon-${index + 1}`}
                        />
                      </CommandIcon>
                      <Heading content={`/${cmd.command}`} {...cmdTitle} />
                    </CommandAbout>
                    <MarkdownText
                      {...cmdDescription}
                      as="div"
                      dangerouslySetInnerHTML={{ __html: cmd.description }}
                    />
                  </CommandDetails>
                </CommandItem>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

CommandSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  cmdTitle: PropTypes.object,
  cmdDescription: PropTypes.object,
};

CommandSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '140px'],
    pb: ['150px', '160px', '160px', '180px', '210px'],
    bg: 'offWhite',
  },
  secTitleWrapper: {
    mb: ['65px', '65px', '80px', '90px', '105px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: 'headingColor',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: 'textColor',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: ['30px', '30px', '30px', '50px', '50px'],
  },
  cmdTitle: {
    fontSize: ['16px', '18px', '18px', '20px', '20px'],
    fontWeight: '600',
    color: 'headingColor',
    mb: '12px',
  },
  cmdDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: 'textColor',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default CommandSection;
