import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../../components/Navbar';
import Drawer from '../../../components/Drawer';
import Logo from '../../../components/UIElements/Logo';
import Box from '../../../components/Box';
import HamburgMenu from '../../../components/HamburgMenu';
import Container from '../../../components/UI/Container';
import { DrawerContext } from '../../../contexts/DrawerContext';
import ScrollSpyMenu from '../../../components/ScrollSpyMenu';
import Button from "../../../components/Button"

import { graphql, useStaticQuery } from "gatsby"

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const { docsNavBarYaml: { logo, callToAction }, allDocsMenuItemsYaml: { nodes: menuItems } } = useStaticQuery(graphql`
    query {
      docsNavBarYaml {
        logo {
          images {
            main {
              publicURL
            }
            alt {
              publicURL
            }
          }
          title
        }
        callToAction {
          label
          href
        }
      }
      allDocsMenuItemsYaml {
        nodes {
          label
          offset
          path
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="app_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={logo.images.main.publicURL}
            title={logo.title}
            logoStyle={logoStyle}
            className="logo-main"
          />
          <Logo
            href="/"
            logoSrc={logo.images.alt.publicURL}
            title={logo.title}
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menuItems}
              offset={-70}
            />
            <a href={callToAction.href} className="navbar_button">
              <Button {...button} title={callToAction.label}/>
            </a>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="white" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={menuItems}
                drawerClose={true}
                offset={-100}
              />
              <a href={callToAction.href} className="navbar_drawer_button">
                <Button {...button} title={callToAction.label}/>
              </a>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  menuItems: PropTypes.array,
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'dangerWithBg',
    minHeight: 'auto',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
