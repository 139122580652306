import styled from 'styled-components';
import { rgba } from "polished"
import { themeApply, themeRequire } from "../../../components/theme-utils"

export const CommandItem = styled.div`
  position: relative;
  background-color: ${themeRequire('colors.white')};
  border-radius: 10px;
  box-shadow: 0.521px 2.954px 50px 0px ${themeApply('colors.infos.3', color => rgba(color, 0.1))};
`;

export const CommandDetails = styled.div`
  padding: 85px 60px 55px 60px;
  ul {
    padding-left: 1.5rem;
    li {
      list-style-type: disc;
    }
  }
  @media (max-width: 1199px) {
    padding: 70px 45px 40px 45px;
  }
  @media (max-width: 990px) {
    padding: 60px 35px 30px 35px;
  }
  @media (max-width: 575px) { 
    padding: 35px 25px 20px 25px;
  }
`;

export const CommandIcon = styled.div`
  flex: 0 0 65px;
  max-width: 65px;
  margin-right: 25px;
  @media (max-width: 990px) {
    flex: 0 0 30px;
    max-width: 30px;
    margin-right: 15px;
  }
  @media (max-width: 575px) {
    flex: 0 0 25px;
    max-width: 25px;
    margin-right: 12px;
  }
`;

export const CommandAbout = styled.div`
  display: flex;
  align-items: center;
`;


export const CommandTOC = styled.ul`
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) { 
    flex-wrap: wrap;
  }
`;


export const CommandTOCItem = styled.li`
  flex-grow: 1;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
  img {  
    display: inline-block;
    margin: 15px;
    max-width: 30px;
  }
  a {
    color: ${themeRequire('colors.primaries.5')};
    &:visited {
      color: ${themeRequire('colors.primaries.5')};    
    }
    &:hover {
      color: ${themeRequire('colors.primaries.4')};    
    }
  }
`;
